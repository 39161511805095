import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

interface IClanCreationValues {
  name: string;
  tag: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldSpacer: {
      marginBottom: theme.spacing(2),
    }
  }),
);

const validationSchema = yup.object({
  name: yup
    .string()
    .min(2, 'Clan name should be at least 2 characters long')
    .max(64, 'Clan name should not be longer than 64 characters long')
    .required('Clan name is required'),
  tag: yup
    .string()
    .min(2, 'Clan tag should be at least 2 characters long')
    .max(10, 'Clan tag should not be longer than 64 characters long')
    .required('Clan tag is required'),
  profile_image: yup
    .string()
    .required('Profile image link is required'),
  cover_image: yup
    .string()
    .required('Cover image link is required'),
  description: yup
    .string()
    .max(1000, 'Clan description may not exceed 1000 characters')
    .required('Clan description is required'),
});

const ClanCreationForm = () => {

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      name: '',
      tag: '',
      profile_image: '',
      cover_image: '',
      description: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values: IClanCreationValues) => {
      // alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          className={classes.fieldSpacer}
          id="name"
          name="name"
          label="Clan Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <TextField
          fullWidth
          className={classes.fieldSpacer}
          id="tag"
          name="tag"
          label="Clan Tag"
          value={formik.values.tag}
          onChange={formik.handleChange}
          error={formik.touched.tag && Boolean(formik.errors.tag)}
          helperText={formik.touched.tag && formik.errors.tag}
        />
        <TextField
          fullWidth
          className={classes.fieldSpacer}
          id="profile_image"
          name="profile_image"
          label="Profile Image Link"
          value={formik.values.profile_image}
          onChange={formik.handleChange}
          error={formik.touched.profile_image && Boolean(formik.errors.profile_image)}
          helperText={formik.touched.profile_image && formik.errors.profile_image}
        />
        <TextField
          fullWidth
          className={classes.fieldSpacer}
          id="cover_image"
          name="cover_image"
          label="Cover Image Link"
          value={formik.values.cover_image}
          onChange={formik.handleChange}
          error={formik.touched.cover_image && Boolean(formik.errors.cover_image)}
          helperText={formik.touched.cover_image && formik.errors.cover_image}
        />
        <TextField
          fullWidth
          multiline
          rows={5}
          className={classes.fieldSpacer}
          id="description"
          name="description"
          label="Clan Description"
          value={formik.values.description}
          onChange={formik.handleChange}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
        />
        <Button color="primary" variant="outlined" fullWidth type="submit">
          Submit
        </Button>
      </form>
    </div>
  );
};

export default ClanCreationForm;