import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import Typography from '@mui/material/Typography';

import { ClanService } from '../services/api';
import { IClanOverview } from '../interfaces/clan';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    coverImageContainer: {
      marginTop: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
      boxShadow: theme.shadows[3],
      width: '100%',
      height: '500px',
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    },
    profileImageContainer: {
      marginLeft: theme.spacing(4),
      marginTop: '-100px',
      padding: '5px',
      width: '310px',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[3],
    },
    profileImage: {
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
      maxWidth: '300px',
      height: '300px',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    profileGlanceInfo: {
      margin: theme.spacing(1),
    },
    profileGlanceInfoNameAndTag: {
      display: 'flex',
      justifyContent: 'space-between',
    }
  }),
);

interface IClanProps {
  slug: string;
}

const ClanPage = (props: IClanProps) => {

  const { slug } = props;

  const classes = useStyles();

  const [clan, setClan] = useState<IClanOverview>();

  useEffect(() => {
    let mounted = true;
    const queryClans = async () => {
      let results = await ClanService.getClanBySlug(slug)
      console.log({results})
      if(mounted) {
        if(results?.data) {
          setClan(results?.data)
        }
      }
    }
    queryClans();
    return () => {
      mounted = false;
    }
  }, [slug])

  return (
    <Container maxWidth="lg">
        <div className={classes.coverImageContainer} style={{backgroundImage: `url(${clan?.cover_image && clan?.cover_image})`}}/>
        <div className={classes.profileImageContainer}>
          <div className={classes.profileImage} style={{backgroundImage: `url(${clan?.profile_image && clan?.profile_image})`}}/>
          <div className={classes.profileGlanceInfo}>
            <div className={classes.profileGlanceInfoNameAndTag}>
              <Typography variant="h5" component="div">
                {clan?.name}
              </Typography>
              <Typography variant="h6" component="div">
                {clan?.tag}
              </Typography>
            </div>
          </div>
        </div>
    </Container>
  )
};

export default ClanPage;