import React, {useState, useEffect, Fragment} from 'react';
import { withRouter, RouteComponentProps } from "react-router";

import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import HomeIcon from '@mui/icons-material/Home';
import ClansIcon from '@mui/icons-material/Api';
import PlayersIcon from '@mui/icons-material/Groups';
import RulesIcon from '@mui/icons-material/LocalPolice';
import LeaderboardIcon from '@mui/icons-material/AutoGraph';
import FragMoviesIcon from '@mui/icons-material/LiveTv';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { PropsFromRedux } from '../containers/NavigationLeftSideBarContainer';

interface INavigationMenu { 
  text: string;
  path: string;
  icon: any;
  comingSoon: boolean,
  children?: INavigationMenu[];
}

const navigationMenu: INavigationMenu[] = [
  {
    text: 'Home',
    path: '/',
    icon: <HomeIcon />,
    comingSoon: false,
  },
  {
    text: 'Frag Movies',
    path: '/frag-movies',
    icon: <FragMoviesIcon />,
    comingSoon: true,
  },
  {
    text: 'Clans',
    path: '/clans',
    icon: <ClansIcon />,
    comingSoon: true,
  },
  {
    text: 'Players',
    path: '/players',
    icon: <PlayersIcon />,
    comingSoon: true,
  },
  {
    text: 'Leaderboard',
    path: '/leaderboard',
    icon: <LeaderboardIcon />,
    comingSoon: true,
  },
  {
    text: 'League Rules',
    path: '/rules',
    icon: <RulesIcon />,
    comingSoon: true,
  },
  // {
  //   text: 'With Children',
  //   icon: <AccountTreeIcon />,
  //   children: [
  //     {
  //       text: 'Example',
  //       path: '/example',
  //       icon: <ExampleIcon />
  //     },
  //     {
  //       text: 'Home',
  //       path: '/',
  //       icon: <HomeIcon />
  //     },
  //   ]
  // },
];

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

type PropsWithRouter = RouteComponentProps & PropsFromRedux;

function NavigationLeftSideBar(props: PropsWithRouter) {
  const classes = useStyles();

  const [openCollapseSections, setOpenCollapseSections] = useState<Number[]>([]);

  const [localShowLeftMenu, setLocalShowLeftMenu] = useState(false)

  useEffect(() => {
    setLocalShowLeftMenu(props.showLeftMenu)
  }, [props.showLeftMenu])

  const toggleDrawer = (setOpen: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    props.setShowLeftMenu(setOpen)
  };

  const toggleOpenCollapseState = (navigationMenuIndex: number) => {
    // Check if the navigationMenuIndex is currently in openCollapseSections
    let indexInCurrentState = openCollapseSections.indexOf(navigationMenuIndex)
    if(indexInCurrentState === -1) {
      // Currently not open
      // We add this index to the list of open collapsable areas to open it
      let newOpenCollapseSections = [...openCollapseSections, navigationMenuIndex];
      setOpenCollapseSections(newOpenCollapseSections);
    } else {
      // Currently open
      // We remove this index from the list of open collapsable areas to close it
      let newOpenCollapseSections = [...openCollapseSections].filter(item => item !== navigationMenuIndex);
      setOpenCollapseSections(newOpenCollapseSections);
    }
  }

  return (
    <div>
        <React.Fragment key={'left'}>
            <Drawer  
              PaperProps={{
                sx: {
                  background: 'linear-gradient(-45deg, rgb(0 20 0), rgb(0 0 0))'
                }
              }} 
              anchor={'left'} open={localShowLeftMenu} onClose={toggleDrawer(false)}
            >
                <div
                    className={classes.list}
                    role="presentation"
                >
                  <List style={{paddingBottom: 0}}>
                      {navigationMenu.map((item, index) => {
                            return (
                              <>
                                {!item.comingSoon &&
                                  <div key={`parent-${index}`}>
                                  <ListItem 
                                    onClick={() => {
                                      if(item.path) {
                                        props.history.push(item.path)
                                        props.setShowLeftMenu(false)
                                      } else if (item.children) {
                                        toggleOpenCollapseState(index)
                                      }
                                    }}
                                    button
                                  >
                                      <ListItemIcon>{item.icon}</ListItemIcon>
                                      <ListItemText primary={item.text} />
                                      {item.children &&
                                        <>
                                          {openCollapseSections.indexOf(index) > -1 ? <ExpandLess /> : <ExpandMore />}
                                        </>
                                      }
                                  </ListItem>
                                  {item.children &&
                                    <Collapse in={openCollapseSections.indexOf(index) > -1} timeout="auto" unmountOnExit>
                                      <List component="div" disablePadding>
                                        {item.children && item.children.map((child, childIndex) => 
                                            <ListItemButton
                                              onClick={() => {
                                                if(child.path.length > 0) {
                                                  props.history.push(child.path)
                                                  props.setShowLeftMenu(false)
                                                }
                                              }}
                                              key={`child-${index}-${childIndex}`}
                                              sx={{ pl: 4 }}
                                            >
                                              <ListItemIcon>{child.icon}</ListItemIcon>
                                              <ListItemText primary={child.text} />
                                            </ListItemButton>
                                        )}
                                      </List>
                                    </Collapse>
                                  }
                                </div>
                              }
                            </>
                          )
                        }
                      )}
                  </List>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <Typography variant="h5" style={{position: 'absolute', left: '50%', transform: 'translateX(-50%)rotate(-45deg)', textAlign: 'center', textTransform: 'uppercase'}}>
                      under<br/>construction
                    </Typography>
                    <div style={{opacity: 0.35, pointerEvents: 'none'}}>
                      <List style={{paddingTop: 0}}>
                          {navigationMenu.map((item, index) => {
                                return (
                                  <>
                                    {item.comingSoon &&
                                      <div key={`parent-${index}`}>
                                      <ListItem 
                                        onClick={() => {
                                          if(item.path) {
                                            props.history.push(item.path)
                                            props.setShowLeftMenu(false)
                                          } else if (item.children) {
                                            toggleOpenCollapseState(index)
                                          }
                                        }}
                                        button
                                      >
                                          <ListItemIcon>{item.icon}</ListItemIcon>
                                          <ListItemText primary={item.text} />
                                          {item.children &&
                                            <>
                                              {openCollapseSections.indexOf(index) > -1 ? <ExpandLess /> : <ExpandMore />}
                                            </>
                                          }
                                      </ListItem>
                                      {item.children &&
                                        <Collapse in={openCollapseSections.indexOf(index) > -1} timeout="auto" unmountOnExit>
                                          <List component="div" disablePadding>
                                            {item.children && item.children.map((child, childIndex) => 
                                                <ListItemButton
                                                  onClick={() => {
                                                    if(child.path.length > 0) {
                                                      props.history.push(child.path)
                                                      props.setShowLeftMenu(false)
                                                    }
                                                  }}
                                                  key={`child-${index}-${childIndex}`}
                                                  sx={{ pl: 4 }}
                                                >
                                                  <ListItemIcon>{child.icon}</ListItemIcon>
                                                  <ListItemText primary={child.text} />
                                                </ListItemButton>
                                            )}
                                          </List>
                                        </Collapse>
                                      }
                                    </div>
                                  }
                                </>
                              )
                            }
                          )}
                      </List>
                    </div>
                  </div>
                </div>
            </Drawer>
        </React.Fragment>
    </div>
  );
}

export default withRouter(NavigationLeftSideBar)