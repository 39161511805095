import {combineReducers} from 'redux';
import showLeftMenu from './showLeftMenu';
import activeAccount from './activeAccount';
import darkMode from './darkMode';
import accessToken from './accessToken';

const rootReducer = combineReducers({
    showLeftMenu,
    activeAccount,
    darkMode,
    accessToken,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;