import React, { useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import JoinWaitlistForm from '../components/JoinWaitlistForm';

const waitingListModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        minWidth: 275,
        marginBottom: 15
    },
    title: {
        fontSize: 14,
    },
    paragraphSpacing: {
        marginTop: theme.spacing(4)
    }
  }),
);

const HomePage = () => {
    const classes = useStyles();

    const [showWaitlistModal, setShowWaitlistModal] = useState(false);

    const handleAuthModalClose = () => {
        setShowWaitlistModal(false);
    }

    return (
        <Container maxWidth="md" style={{marginBottom: 300, opacity: 0}}>
            <Typography className={classes.paragraphSpacing} variant="h2">
                Welcome!
            </Typography>
            {/* <Typography className={classes.paragraphSpacing} variant="h6">
                mw2.co.za is an <a href="https://github.com/JayWelsh/mw2-za-ui" rel="noopener noreferrer" target="_blank">open-source</a> initiative to improve the current competitive CoD scene.
            </Typography> */}
            <Typography className={classes.paragraphSpacing} variant="h6">
                mw2.co.za is an initiative to improve the current competitive CoD scene in South Africa.
            </Typography>
            {/* <Typography className={classes.paragraphSpacing} variant="h6">
                Believe it or not, the South African competitive CoD scene used to be a lot more alive and active than we have seen in the past decade. This project is built and inspired by the values which formed the basis of OG competitive CoD in the hopes to reignite the initial spark which used to make CoD such a balanced competitive game.
            </Typography> */}
            <Typography className={classes.paragraphSpacing} variant="h6" style={{fontWeight: 400}}>
                We are building a platform to help form a social layer for players and clans alike to connect and improve their gameplay - from sharing frag movies to hosting virtual leagues.
            </Typography>
            <Typography className={classes.paragraphSpacing} variant="h6" style={{fontWeight: 400}}>
                Want to show support for the movement? Spread the <span style={{color: 'lime', fontFamily: 'monospace', marginLeft: 6, marginRight: 6}}>MW2.CO.ZA</span> website URL in game chat!
            </Typography>
            {/* Lobby disbandment means that after each match lobbies are closed and players are reshuffled into different groups. mw2.co.za seeks to help players stay aligned more easily without feeling like every game's social dynamics are temporary, or in some cases, even worthless. */}
            <Button className={[classes.paragraphSpacing, 'gradient-button', 'large-button-text'].join(' ')} fullWidth onClick={() => setShowWaitlistModal(true)}>
                <div className='gradient-button-inner'></div>
                Join Waitlist
            </Button>
            <Modal
                open={showWaitlistModal}
                onClose={handleAuthModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={waitingListModalStyle}>
                    <JoinWaitlistForm/>
                </Box>
            </Modal>
        </Container>
    )
};

export default HomePage;