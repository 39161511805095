import * as React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { Link } from 'react-router-dom';

interface IClanCardProps {
  coverImg: string;
  profileImg: string;
  name: string;
  tag: string;
  description?: string;
  slug: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    clanProfileImageContainer: {
      width: '100px',
      height: '100px',
      marginTop: '-35px',
      marginLeft: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
      boxShadow: theme.shadows[3],
    },
    clanProfileImage: {
      maxWidth: '100%',
    },
    profileImageAndNameContainer: {
      display: 'flex'
    },
    clanNameAndTagContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    description: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }
  }),
);

export default function ClanCard(props: IClanCardProps) {
  const { 
    coverImg,
    profileImg,
    name,
    tag,
    description,
    slug
  } = props;

  const classes = useStyles();

  return (
    <Card>
      <Link className="no-decorate" to={`/clan/${slug}`}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="180"
            image={coverImg}
            alt="green iguana"
          />
          <div className={classes.profileImageAndNameContainer}>
            <div className={classes.clanProfileImageContainer}>
              <img className={classes.clanProfileImage}  src={profileImg} alt={name + '-profile-image'}/>
            </div>
            <div className={classes.clanNameAndTagContainer}>
              <Typography variant="h5" component="div">
                {name}
              </Typography>
              <Typography variant="h6" component="div">
                {tag}
              </Typography>
            </div>
          </div>
          <CardContent>
            <Typography className={classes.description} variant="body2" color="text.secondary">
              {description || "No description found."}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
}