import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { useSnackbar } from 'notistack';

import { WaitingListService, IApiResponse } from '../services/api';

interface IJoinWaitlistFormValues {
  email_address: string;
  preferred_username: string;
  in_clan: boolean;
  wants_clan: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldSpacer: {
      marginBottom: theme.spacing(3),
    },
    labelSpacer: {
      marginBottom: theme.spacing(1),
    },
    successText: {
      color: '#29ff00',
    },
    errorText: {
      color: '#ff0000',
    },
    formSwitches: {
      display: 'flex',
      flexDirection: 'column',
    }
  }),
);

const validationSchema = yup.object({
  email_address: yup
    .string()
    .min(4, 'Email address should be at least 4 characters long')
    .max(64, 'Email address should not be longer than 64 characters long')
    .email()
    .required('Email address is required'),
  preferred_username: yup
    .string()
    .min(1, 'Preferred username should be at least 1 characters long')
    .max(32, 'Preferred username should not be longer than 32 characters long'),
  in_clan: yup
    .boolean()
    .required(),
  wants_clan: yup
    .boolean()
    .required(),
});

const JoinWaitlistForm = () => {

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      email_address: '',
      preferred_username: '',
      in_clan: false,
      wants_clan: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values: IJoinWaitlistFormValues) => {
      // alert(JSON.stringify(values, null, 2));
      try {
        let response: IApiResponse = await WaitingListService.signInWithSignedMessageData({
          email_address: values.email_address,
          preferred_username: values.preferred_username,
          in_clan: values.in_clan,
          wants_clan: values.wants_clan
        })
        if(response?.data?.success) {
          enqueueSnackbar('Successfully joined waiting list!', {variant: 'success'})
        }
      } catch(e) {
        console.log({e})
        let showError = ``;
        //@ts-ignore
        if(e?.data?.data?.errors) {
          //@ts-ignore
          for(let error of e?.data?.data?.errors) {
            showError += `${error.msg}${error.param ? `: ${error.param}` : ''}\r\n`
          }
        }
        console.log({showError})
        enqueueSnackbar(showError, {variant: 'error'})
      }
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <Typography variant="overline" component="div" className={classes.labelSpacer}>
              Your email address
          </Typography>
          <TextField
            fullWidth
            id="email_address"
            name="email_address"
            label="Email Address (Required)"
            className={classes.fieldSpacer}
            value={formik.values.email_address}
            onChange={formik.handleChange}
            error={(formik.touched.email_address && Boolean(formik.errors.email_address))}
            helperText={(formik.touched.email_address && formik.errors.email_address)}
          />
          <Typography variant="overline" component="div" className={classes.labelSpacer}>
              Your preferred mw2.co.za username
          </Typography>
          <TextField
            fullWidth
            id="preferred_username"
            name="preferred_username"
            label="Preferred Username (Optional)"
            className={classes.fieldSpacer}
            value={formik.values.preferred_username}
            onChange={formik.handleChange}
            error={(formik.touched.preferred_username && Boolean(formik.errors.preferred_username))}
            helperText={(formik.touched.preferred_username && formik.errors.preferred_username)}
          />
          <div className={classes.formSwitches}>
            <Typography variant="overline" component="div">
                Your current clan status
            </Typography>
            <FormControlLabel
              id="in_clan"
              name="in_clan"
              label="I am currently in a clan"
              value={formik.values.in_clan}
              onChange={formik.handleChange}
              control={<Switch />}
            />
            <FormControlLabel
              id="wants_clan"
              name="wants_clan"
              label="I want to be in a clan"
              value={formik.values.wants_clan}
              onChange={formik.handleChange}
              control={<Switch />}
              className={classes.fieldSpacer}
            />
          </div>
          <Button color="primary" variant="outlined" fullWidth type="submit">
            Join Waitlst
          </Button>
        </div>
      </form>
    </div>
  );
};

export default JoinWaitlistForm;