interface IAccessToken {
  type: string;
  accessToken: string;
}

const setAccessToken = (state = false, action: IAccessToken) => {
  console.log("Setting access token", action);
  switch (action.type) {
      case 'SET_ACCESS_TOKEN':
        return action.accessToken
      default:
        return state
  }
}

export default setAccessToken;