import { $axios } from './axios';
import SnackbarUtils from '../utils/SnackbarUtilsConfigurator';

$axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/`;

export interface IApiResponse {
    data?: any;
}

export const ApiService = {

  query(resource: string, params: object) {
      return $axios.get(resource, {params}).catch(error => {
          throw new Error(`[RWV] ApiService ${error}`);
      });
  },

  get(resource: string, slug = "") {
    return $axios.get(`${resource}/${slug}`).catch(error => {
        SnackbarUtils.error('API connection unavailable');
        throw new Error(`ApiService ${error}`);
    });
  },

  post(resource: string, params: any, config = undefined) {
      return $axios.post(`${resource}`, params, config);
  },

  // update(resource, slug, params) {
  //     return $axios.put(`${resource}/${slug}`, params);
  // },

  // put(resource, params) {
  //     return $axios.put(`${resource}`, params);
  // },

  // delete(resource) {
  //     return $axios.delete(resource).catch(error => {
  //         throw new Error(`[RWV] ApiService ${error}`);
  //     });
  // }
};

export const ClanService = {
    list(pagination = {perPage: 6, page: 1}, filter = {}) {
        return ApiService.query('clans', {...pagination, ...filter});
    },
    getClanBySlug(slug: string) {
        return ApiService.get('clan', slug);
    },
};

interface ISignInData {
    signed_message: string
    raw_message: string
    wallet_address: string
}

interface IJoinWaitingListData {
    email_address: string
    preferred_username: string
    in_clan: boolean
    wants_clan: boolean
}

export const AuthService = {
    getNonce(address: string) {
        return ApiService.get('auth/nonce', address);
    },
    signInWithSignedMessageData(data: ISignInData) {
        return ApiService.post('auth/sign-in', data);
    }
}

export const WaitingListService = {
    signInWithSignedMessageData(data: IJoinWaitingListData) {
        return ApiService.post('waiting-list/join', data);
    }
}

export const UserService = {
    checkUsernameAvailability(username: string) {
        return ApiService.get('username-availability', username);
    },
}