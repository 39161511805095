import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { ClanService } from '../services/api';

import ClanCard from '../components/ClanCard';
import ClanCreationForm from '../components/ClanCreationForm';

import { IClanOverview } from '../interfaces/clan';

const ClansPage = () => {

  const [page, setPage] = useState(1);
  const [clans, setClans] = useState<IClanOverview[]>([]);

  useEffect(() => {
    let mounted = true;
    const queryClans = async () => {
      let results = await ClanService.list({
        perPage: 12,
        page,
      })
      console.log({results})
      if(mounted) {
        if(results?.data?.data && results?.data?.pagination?.currentPage) {
          setClans(results?.data?.data)
          setPage(results?.data?.pagination?.currentPage);
        }
      }
    }
    queryClans();
    return () => {
      mounted = false;
    }
  }, [page])

  return (
    <Container maxWidth="lg">
        <h1>Clans</h1>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={4}>
            {clans?.length > 0 && 
              clans.map(clan => 
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <ClanCard
                    coverImg={clan.cover_image}
                    profileImg={clan.profile_image}
                    name={clan.name}
                    tag={clan.tag}
                    description={clan.description}
                    slug={clan.slug}
                  />
                </Grid>
              )
            }
          </Grid>
        </Box>
        <div style={{marginTop: '50px'}}/>
        <ClanCreationForm/>
    </Container>
  )
};

export default ClansPage;